import React, { useState, useRef } from 'react';
import './score-card.css'
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Dropdown } from 'primereact/dropdown'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Menu} from 'primereact/menu';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));


export default function Card() {

    const classes = useStyles();
    let menu = useRef(null);
    const [displayBasic, setDisplayBasic] = useState(false);

    const default_game = () => {
        return {
            ones: '',
            twos: '',
            threes: '',
            fours: '',
            fives: '',
            sixes: '',
            top_total: 0,
            top_bonus: 0,
            fullhouse: '',
            threeofakind: '',
            fourofakind: '',
            shortrun: '',
            longrun: '',
            yatzee: '',
            chance: '',
            bottom_total: 0,
            grand_total: 0,
        }
    }

    let gameState = {};
    if (JSON.parse(localStorage.getItem('game'))) {
        gameState = JSON.parse(localStorage.getItem('game'));
    }
    else {
        gameState = default_game();
    };

    const [game, setGame] = useState(gameState);

    /**
     * Handle value changes
     * @param {*} event 
     */
    const handleChange = (event) => {
        console.log(event.target.name);
        console.log(event.target.value);

        if (isNaN(event.target.value) || event.target.value === '') {
            game[event.target.name] = '';
        }
        else {
            game[event.target.name] = parseInt(event.target.value, 10);
        }
        calculateTopTotal();
        calculateBottomTotal();
        game.grand_total = game.top_total + game.top_bonus + game.bottom_total
        // Create new object to ensure re render when setGame called
        setGame(Object.assign({}, game));
        // todo - how to do this only when leave page?
        localStorage.setItem("game", JSON.stringify(game));
        event.preventDefault();
    }

    const handleChange2 = (event) => {
        console.log(event.target.name);
        console.log(event.value);

        if (isNaN(event.value) || event.value === '') {
            game[event.target.name] = '';
        }
        else {
            game[event.target.name] = parseInt(event.value, 10);
        }
        calculateTopTotal();
        calculateBottomTotal();
        game.grand_total = game.top_total + game.top_bonus + game.bottom_total
        // Create new object to ensure re render when setGame called
        setGame(Object.assign({}, game));
        // todo - how to do this only when leave page?
        localStorage.setItem("game", JSON.stringify(game));
        event.preventDefault();
    }

    /**
 * Calculate total of top section
 * @param {*} stat 
 */
    const calculateTopTotal = () => {
        let total = 0;
        const scores = ['ones', 'twos', 'threes', 'fours', 'fives', 'sixes']
        for (let index = 0; index < scores.length; index++) {
            let key = scores[index];
            if (!isNaN(game[key]) && game[key] !== '') {
                total += game[key];
            }
        }

        if (total > 62) {
            game.top_bonus = 35;
        }
        else {
            game.top_bonus = 0;
        }

        game.top_total = total
    }

    /**
     * Calculate tottal of bottom section
     * @param {*} stat 
     */
    const calculateBottomTotal = () => {
        let total = 0;
        const scores = ['threeofakind', 'fourofakind', 'fullhouse', 'shortrun', 'longrun', 'yatzee', 'chance']
        for (let index = 0; index < scores.length; index++) {
            let key = scores[index];
            if (!isNaN(game[key]) && game[key] !== '') {
                total += game[key];
            }
        }

        game.bottom_total = total
    }



    /**
     * Clear current board
     */
    const reset = () => {

        localStorage.removeItem('game');
        localStorage.setItem("game1", JSON.stringify(game));
        setGame(default_game());
        setDisplayBasic(false)

    }

    
    const showDialog = () => {
        setDisplayBasic(true)
    }

    const onHide = () => {
        setDisplayBasic(false);
    }


    let items = [
        {label: 'Reset', icon: 'pi pi-fw pi-plus', command:()=>{ showDialog(); } }
    ];
    
    const dialogFooter = () => {
        return (
            <div>
               <Button label="Yes" icon="pi pi-check" onClick={() => reset() } className="p-button-secondary" />
                <Button label="No" icon="pi pi-times" onClick={() => setDisplayBasic(false)} />
            </div>
        );
    }
    

    return (
        
        <div className="CardPage">
            <Dialog header="Confirm" visible={displayBasic} style={{width: '75vw'}} 
            className="confirm-dialog"
            footer={dialogFooter()}
            onHide={() => onHide()}
            >
                <p>Are you sure you want to clear current game?</p>
            </Dialog>
            <AppBar position="static" className="topbar">
            <Toolbar>
            <Menu model={items} popup={true} ref={menu} id="popup_menu" />
          <IconButton edge="start" 
          onClick={event => menu.current.toggle(event)}
          className={classes.menuButton} 
          color="inherit" aria-label="menu">
            <MenuIcon />
            
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Yatzee
          </Typography>
        </Toolbar>
            </AppBar>
            <div className="Card">
                <div className="CardRow"
                style={{
                    color: game.ones !== "" ? 'gray' : 'black'
                  }}>
                    <div>Total of Ones</div>
                    <div>
                    <Dropdown value={game.ones} onChange={handleChange2} name="ones"
                            options={[
                                { label: " ", value: "" },
                                { label: '0', value: 0 },
                                { label: '1', value: 1 },
                                { label: '2', value: 2 },
                                { label: '3', value: 3 },
                                { label: '4', value: 4 },
                                { label: '5', value: 5 }
                            ]} />
                    </div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.twos !== "" ? 'gray' : 'black'
                  }}>
                    <div>Total of Twos</div>
                    <div>
                        <Dropdown value={game.twos} onChange={handleChange2} name="twos"
                            options={[
                                { label: " ", value: "" },
                                { label: '0', value: 0 },
                                { label: '2', value: 2 },
                                { label: '4', value: 4 },
                                { label: '6', value: 6 },
                                { label: '8', value: 8 },
                                { label: '10', value: 10 }
                            ]} />
                    </div>

                </div>
                <div className="CardRow"
                style={{
                    color: game.threes !== "" ? 'gray' : 'black'
                  }}>
                    <div>Total of Threes</div>
                    <div>
                        <Dropdown value={game.threes} onChange={handleChange2} name="threes"
                            options={[
                                { label: " ", value: "" },
                                { label: '0', value: 0 },
                                { label: '3', value: 3 },
                                { label: '6', value: 6 },
                                { label: '9', value: 9 },
                                { label: '12', value: 12 },
                                { label: '15', value: 15 }
                            ]} />
                    </div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.fours !== "" ? 'gray' : 'black'
                  }}>
                    <div>Total of Fours</div>
                    <div>
                    <Dropdown value={game.fours} onChange={handleChange2} name="fours"
                            options={[
                                { label: " ", value: "" },
                                { label: '0', value: 0 },
                                { label: '4', value: 4 },
                                { label: '8', value: 8 },
                                { label: '12', value: 12 },
                                { label: '16', value: 16 },
                                { label: '20', value: 20 }
                            ]} />
                        </div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.fives !== "" ? 'gray' : 'black'
                  }}>
                    <div>Total of Fives</div>
                    <div>
                    <Dropdown value={game.fives} onChange={handleChange2} name="fives"
                            options={[
                                { label: " ", value: "" },
                                { label: '0', value: 0 },
                                { label: '5', value: 5 },
                                { label: '10', value: 10 },
                                { label: '15', value: 15 },
                                { label: '20', value: 20 },
                                { label: '25', value: 25 }
                            ]} />
                    </div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.sixes !== "" ? 'gray' : 'black'
                  }}>
                    <div>Total of Sixes</div>
                    <div>
                    <Dropdown value={game.sixes} onChange={handleChange2} name="sixes"
                            options={[
                                { label: " ", value: "" },
                                { label: '0', value: 0 },
                                { label: '6', value: 6 },
                                { label: '12', value: 12 },
                                { label: '18', value: 18 },
                                { label: '24', value: 24 },
                                { label: '30', value: 30 }
                            ]} />
                    </div>
                </div>
                <div className="Total CardRow">
                    <div>Bonus if >63</div>
                    <div>{game.top_bonus}</div>
                </div>
                <div className="Total CardRow">
                    <div>Total</div>
                    <div>{game.top_total}</div>
                </div>
                <div className="Total CardRow">
                    <div>Total Upper Section</div>
                    <div>{game.top_total + game.top_bonus}</div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.threeofakind !== "" ? 'gray' : 'black'
                  }}>
                    <div>Three of a kind</div>
                    <div><input type='number' max='30' onChange={handleChange}
                        value={game.threeofakind} name="threeofakind"></input></div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.fourofakind !== "" ? 'gray' : 'black'
                  }}>
                    <div>Four of a kind</div>
                    <div><input type='number' max='30' onChange={handleChange}
                        value={game.fourofakind} name="fourofakind"></input></div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.fullhouse !== "" ? 'gray' : 'black'
                  }}>
                    <div>Full House</div>
                    <div>
                        <Dropdown value={game.fullhouse} onChange={handleChange2} name="fullhouse"
                            options={[
                                { label: " ", value: "" },
                                { label: '0', value: 0 },
                                { label: '25', value: 25 }
                            ]} />
                    </div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.shortrun !== "" ? 'gray' : 'black'
                  }}>
                    <div>Short Run</div>
                    <div>
                        <Dropdown value={game.shortrun} onChange={handleChange2} name="shortrun"
                            options={[
                                { label: " ", value: "" },
                                { label: '0', value: 0 },
                                { label: '30', value: 30 }
                            ]} />
                    </div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.longrun !== "" ? 'gray' : 'black'
                  }}>
                    <div>Long Run</div>
                    <div>
                        <Dropdown value={game.longrun} onChange={handleChange2} name="longrun"
                            options={[
                                { label: " ", value: "" },
                                { label: '0', value: 0 },
                                { label: '40', value: 40 }
                            ]} />
                    </div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.yatzee !== "" ? 'gray' : 'black'
                  }}>
                    <div>Yatzee</div>
                    <div>
                        <Dropdown value={game.yatzee} onChange={handleChange2} name="yatzee"
                            options={[
                                { label: " ", value: "" },
                                { label: '0', value: 0 },
                                { label: '50', value: 50 }
                            ]} />
                    </div>
                </div>
                <div className="CardRow"
                style={{
                    color: game.chance !== "" ? 'gray' : 'black'
                  }}>
                    <div>Chance</div>
                    <div><input type='number' max='30' onChange={handleChange}
                        value={game.chance} name="chance"></input></div>
                </div>
                <div className="Total CardRow">
                    <div>Total Lower Section</div>
                    <div>{game.bottom_total}</div>
                </div>
                <div className="Total CardRow">
                    <div>Total Upper Section</div>
                    <div>{game.top_total + game.top_bonus}</div>
                </div>
                <div className="Total CardRow">
                    <div>Grand Total</div>
                    <div>{game.grand_total}</div>
                </div>
            </div>
        </div>
    );

}









