
import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Menu} from 'primereact/menu';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

export default function History() {

    const classes = useStyles();
    let menu = useRef(null);

    return (
        <div className="CardPage">
            <AppBar position="static" className="topbar">
            <Toolbar>
            
          <IconButton edge="start" 
          className={classes.menuButton} 
          color="inherit" aria-label="menu">
            <MenuIcon />
            
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Yatzee
          </Typography>
        </Toolbar>
            </AppBar>
            <p>TODO - Put history here</p>
        </div>
    )

}