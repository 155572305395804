import React, { useState } from 'react';
import Card from './score-card'
import History from './history'
import './App.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'typeface-roboto';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import CasinoIcon from '@material-ui/icons/CasinoOutlined';


function App() {


    //const classes = useStyles();
    const [value, setValue] = useState(0);


    return (

        <div className="App">
            <div className="content">
            { value ? <History /> : <Card /> }
            </div>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels             
            >
                <BottomNavigationAction label="Game" icon={< CasinoIcon />} />
                <BottomNavigationAction label="History" icon={< HistoryOutlinedIcon />} />
            </BottomNavigation>
        </div>

    );
}


export default App;
